<template>
  <span
    @click="sendMail"
    class="cursor-pointer data-content"
    data-content="book@skinstudio.dk"
  ></span>
</template>

<script>
export default {
  name: "LinkMail",
  methods: {
    sendMail() {
      location.href = "mailto:book@skinstudio.dk";
    },
  },
};
</script>
