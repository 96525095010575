<template>
  <span
    @click="callPhone"
    class="cursor-pointer data-content"
    data-content="+45 5379 7953"
  ></span>
</template>

<script>
export default {
  name: "LinkPhone",
  methods: {
    callPhone() {
      location.href = "tel:+4553797953";
    },
  },
};
</script>
