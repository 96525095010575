<template>
  <main class="contact text-base leading-8">
    <section class="xl:max-w-hd mx-auto">
      <div class="flex flex-wrap md:flex-nowrap md:gap-14">
        <div class="w-full md:w-6/12">
          <div class="aspect-w-1 sm:aspect-w-2 md:aspect-w-1 aspect-h-1 -mx-10 -mt-10 -mb-6 sm:m-0">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2248.6523792580174!2d12.507940915861935!3d55.69503120391377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4652534e41e92413%3A0x5344bb1d33a9b8f5!2sSkin%20Studio!5e0!3m2!1sda!2sdk!4v1641688813907!5m2!1sda!2sdk" style="border: 0" allowfullscreen="" loading="lazy" class="w-full h-full" />
          </div>
        </div>
        <div class="w-full md:w-6/12 lg:5/12">
          <heading-1>Kontakt os</heading-1>
          <ul class="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <li>
              <h3 class="font-medium">Adresse</h3>
              <p>
                Hulgårdsvej 3, st. tv.<br />
                2400 København NV
              </p>
            </li>
            <li>
              <h3 class="font-medium">Kontakt information</h3>
              <p>
                <link-phone />
                <br />
                <link-mail />
              </p>
            </li>
            <li>
              <h3 class="font-medium">Stamdata</h3>
              <p>
                SkinStudio ApS.<br />
                P-nr.: 1025379922
              </p>
            </li>
            <li>
              <h3 class="font-medium">Sociale medier</h3>
              <ul class="flex items-center gap-4 mt-4 text-black">
                <a href="https://instagram.com/skinstudiodk" target="_blank" rel="noopener">
                  <instagram class="h-6" />
                </a>
                <a href="https://facebook.com/skinstudiodk" target="_blank" rel="noopener">
                  <facebook class="h-5" />
                </a>
              </ul>
            </li>
            <li v-if="1 === 2">
              <h3 class="font-medium">Praktisk info</h3>
              <link-directions>Se åbningstider</link-directions>
              <br />
              <link-booking />
              <br />
              <link-directions>Find vej på GPS</link-directions>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Heading1 from "../components/Heading1.vue";
import LinkPhone from "../components/LinkPhone.vue";
import LinkMail from "../components/LinkMail.vue";
import LinkBooking from "../components/LinkBooking.vue";
import LinkDirections from "../components/LinkDirections.vue";
import Facebook from "../assets/icons/Facebook.vue";
import Instagram from "../assets/icons/Instagram.vue";

export default {
  name: "Information",
  components: {
    Heading1,
    LinkPhone,
    LinkMail,
    LinkBooking,
    LinkDirections,
    Instagram,
    Facebook,
  },
};
</script>
